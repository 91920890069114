<template>
  <!-- Game Type5 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type4 my-2"
  >
    <v-container>
      <v-row class="pa-4">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <template
          v-for="item in data.data.navbar"
        >
          <v-col
            v-if="item.items.length !== 0"
            :key="item._id"
            cols="6"
            class="pa-0 px-2 py-1 pb-6 p-relative"
            @click="goVendorPage(item._id)"
          >
            <img
              :src="baseImgUrl + item.image_main"
              width="100%"
              :alt="item.name"
            >
            <div class="p-absolute game-vendor w-100 text-center">
              <span class="game-vender__name px-3 py-1 rounded">{{ item.game_type }}</span>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import card from '../../../../assets/icons/vendor/cardB.svg'
import fish from '../../../../assets/icons/vendor/fishB.svg'
import live from '../../../../assets/icons/vendor/liveB.svg'
import lottery from '../../../../assets/icons/vendor/lotteryB.svg'
import slot from '../../../../assets/icons/vendor/slotB.svg'
import esport from '../../../../assets/icons/vendor/eSportB.svg'
import sport from '../../../../assets/icons/vendor/sportB.svg'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // swiper
    swiperOption: {
      slidesPerView: 5,
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },

    icons: {
      card,
      fish,
      live,
      lottery,
      slot,
      esport,
      sport,
    },
  }),

  computed: {
    ...mapGetters(['theme', 'baseImgUrl']),
  },

  methods: {
    /**
     * 到 vendor 頁
     * @date 2021-12-10
     * @param {string} vendorID
     */
    goVendorPage(vendorID) {
      this.$router.push({
        name: 'vendor',
        params: {
          id: vendorID,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h-56px {
  height: 56px;
}

.card-bg{
  background: #dea554;
}
.fish-bg{
  background: #1cbaff;
}
.live-bg{
  background: #fd683c;
}
.lottery-bg{
  background: #febc08;
}
.slot-bg{
  background: #f08108;
}
.esport-bg{
  background: #27dce6;
}
.sport-bg{
  background: #ae4bff;
}
.game-vendor {
  bottom: 20px;
}
.game-vender__name {
  top: 0;
  right: 0;
   background-image: linear-gradient(110deg, #e6ce71 -5%, #bc954d 20%, #a57539 49%, #c09a50 77%, #e6ce71 100%);
}
</style>
