var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-type4 my-2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "pa-4" },
            [
              _vm._l(_vm.data.data.navbar, function (item) {
                return [
                  item.items.length !== 0
                    ? _c(
                        "v-col",
                        {
                          key: item._id,
                          staticClass: "pa-0 px-2 py-1 pb-6 p-relative",
                          attrs: { cols: "6" },
                          on: {
                            click: function ($event) {
                              return _vm.goVendorPage(item._id)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.baseImgUrl + item.image_main,
                              width: "100%",
                              alt: item.name,
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-absolute game-vendor w-100 text-center",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "game-vender__name px-3 py-1 rounded",
                                },
                                [_vm._v(_vm._s(item.game_type))]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }